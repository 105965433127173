require("dotenv").config();

module.exports = {
    server: {
        host: process.env.VUE_APP_SERVER_HOST || "localhost",
        port: process.env.VUE_APP_SERVER_PORT || 8081,
        https: process.env.VUE_APP_SERVER_HTTPS || false,
    },
    fota: {
        host: process.env.VUE_APP_FOTA_HOST || "localhost",
        port: process.env.VUE_APP_FOTA_PORT || 8082,
        https: process.env.VUE_APP_FOTA_HTTPS || false,
    },
    socket: {
        host: process.env.VUE_APP_SOCKET_HOST || "localhost",
        port: process.env.VUE_APP_SOCKET_PORT || 5000,
        https: process.env.VUE_APP_SOCKET_HTTPS || false,
    },
};
